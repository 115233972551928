<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form class="container">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Név</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label>Szülő Kategória</label>
            <b-form-group>
              <v-select
                v-model="selectedCategory"
                label="name"
                :options="allCategories()"
                @input="selectId($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox
                v-model="formData.active"
                :value="true"
              >
                Aktív
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Mentés
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      formData: {
        name: null,
        parent_id: null,
        active: null,
      },
      selectedCategory: null,
      loading: false,
      required,
    }
  },
  methods: {
    ...mapActions(['fetchCategories', 'updateCategory', 'getCategory']),
    ...mapGetters(['allCategories']),
    initCategories() {
      this.loading = true
      this.fetchCategories().then(res => {
        this.loading = false
      })
    },
    initCategory() {
      this.loading = true
      this.getCategory(this.$route.params.id).then(res => {
        this.formData.id = res.id
        this.formData.name = res.name
        this.formData.parent_id = res.parent.id
        this.formData.active = res.active
        this.selectedCategory = res.parent
        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateCategory(this.formData).then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeres mentés',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.replace({
              name: 'categories-index',
            })
          })
        }
      })
    },
    selectId(e) {
      this.formData.parent_id = e.id
    },
  },
  created() {
    this.initCategories()
    this.initCategory()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
